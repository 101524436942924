import { Typography, Link, useTheme, alpha } from '@mui/material';

function WebsiteCredit() {
  const theme = useTheme();

  return (
    <Typography
      align="center"
      component="p"
      variant="body1"
      sx={{
        textAlign: 'center',
        pt: 2,
        pb: 1,
        fontSize: 12,
        color: alpha(theme.palette.common.white, 0.25),
      }}
    >
      Website by:
      <Link
        href="https://lemonbirdsolutions.com"
        rel="noreferrer noopener"
        target="_blank"
        sx={{
          ml: 1,
          color: 'inherit',
          textDecoration: 'none',
          fontWeight: 'normal',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        LemonBird
      </Link>
    </Typography>
  );
}

export default WebsiteCredit;
